<template>
  <div class="container--fluid pt-3">
    <v-card>
      <v-card-title>
        ข้อมูลเจ้าหน้าที่
        <v-divider class="mx-4" inset vertical></v-divider>
        <Frm_admin
          :dialog_frm_admin="frm_admin_dialog"
          :dataFrm="frm_admin_dataFrm"
          :statAC="statAC"
          @callback_frm_admin="callback_frm_admin1"
          @AddNew="AddNew"
        ></Frm_admin>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.getters.getlistMemberAdmin"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on"
                ><v-icon>mdi-cogs</v-icon> จัดการข้อมูล
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="EditItem(item)">
                <v-list-item-title
                  ><v-icon>mdi-pencil</v-icon> แก้ไขข้อมูล</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Frm_admin from "../components/frm_admin";
export default {
  components: { Frm_admin },
  name: "member",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "รหัสเจ้าหน้าที่",
          align: "start",
          value: "ad_id",
        },
        { text: "ชื่อ-นามสกุล", value: "ad_name" },
        { text: "ตำแหน่ง", value: "ad_position" },
        { text: "จัดการ", value: "actions" },
      ],
      frm_admin_dialog: false,
      frm_admin_dataFrm: {},
      statAC: "",
    };
  },
  async mounted() {
    this.$store.dispatch("getlistMemberAdmin");
  },
  methods: {
    callback_frm_admin1() {
      this.clear();
    },
    clear() {
      this.frm_admin_dialog = false;
      this.frm_admin_dataFrm = {};
    },
    AddNew() {
      this.frm_admin_dialog = true;
      this.statAC = "AddNew";
      this.frm_admin_dataFrm = {};
    },
    EditItem(item) {
      this.frm_admin_dialog = true;
      this.statAC = "Edit";
      this.frm_admin_dataFrm = item;
    },
  },
};
</script>

<style>
</style>