<template>
  <div id="frm_admin">
    <v-layout row justify-center>
      <v-dialog v-model="dialog_frm_admin" persistent max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark @click="AddNew" v-on="on"
            ><v-icon class="mr-2">mdi-account-plus</v-icon>
            เพิ่มเจ้าหน้าที่
          </v-btn>
        </template>
        <form @submit.prevent="submitData">
          <v-card>
            <v-card-title>
              <span class="headline">ข้อมูลเจ้าหน้าที่</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex lg2 md12>
                    <v-text-field
                      label="รหัสเจ้าหน้าที่"
                      v-model="dataFrm.ad_id"
                      hint="รหัสสำหรับเข้าสู่ระบบ"
                      required
                      disabled
                      v-if="statAC == 'Edit'"
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg6 md12>
                    <v-text-field
                      label="ชื่อ-สกุล"
                      v-model="dataFrm.ad_name"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg4 md12>
                    <v-select
                      label="ประเภท"
                      v-model="dataFrm.ad_type"
                      required
                      :items="this.$store.getters.getlistMemberType"
                      item-text="type_name"
                      item-value="type_id"
                    ></v-select>
                  </v-flex>
                  <v-flex lg8 md12>
                    <v-text-field
                      label="ตำแหน่ง"
                      v-model="dataFrm.ad_position"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg4 md12>
                    <v-text-field
                      label="รหัสผ่านเข้าใช้งาน"
                      v-model="dataFrm.ad_pass"
                      required
                      :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (value = !value)"
                      :type="value ? 'password' : 'text'"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" type="submit">บันทึก</v-btn>
              <v-btn @click="close_frm_admin">ปิดหน้านี้</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "frm_admin",
  data() {
    return {
      value: String,
    };
  },
  props: {
    dialog_frm_admin: Boolean,
    dataFrm: {
      ad_id: String,
      ad_name: String,
      ad_pass: String,
      ad_position: String,
      ad_type: String,
      modify_data: String,
    },
    statAC: String,
  },
  methods: {
    clearData() {
      this.dialog_frm_admin = false;
      this.dataFrm = {
        ad_id: "",
        ad_name: "",
        ad_pass: "",
        ad_position: "",
        ad_type: "",
        modify_data: "",
      };
    },
    AddNew() {
      this.$emit("AddNew");
    },
    close_frm_admin() {
      this.clearData();
      this.$emit("callback_frm_admin");
    },
    submitData() {
      let vData = {};
      Object.assign(vData, this.dataFrm, { statAC: this.statAC });
      window.$.post(
        this.$store.state.pathAPI + "member_admin/updateData",
        vData,
        async (param) => {
          await window.Swal.fire(param.title, param.html, param.icon);
          await this.$store.dispatch("getlistMemberAdmin");
          await this.close_frm_admin();
        },
        "json"
      );
    },
  },
};
</script>

<style>
</style>